import $ from "jquery";

function Show() {
    $('#js-locker').fadeIn(300);
    setTimeout(function () {
        $('#js-locker').fadeOut(300);
    }, 10000);
}

function Hide() {
    $('#js-locker').fadeOut(300);
}

export {Show, Hide}