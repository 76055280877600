import { Vue } from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { toBase64 } from '@/services/api.upload';
import { ProfileInformation } from '@/components/pages/model-account/profile-information/model-account-profile-information.interface';
import { ModelAccountState } from '@/store/modelAccount/types';
import { modelAccount } from '@/store/modelAccount';
import apiUserData from "@/services/getUserData.service";
import router from "@/route/routing";
import * as locker from "@/store/locker.js"
import $ from "jquery";
import Dropbox from "@/store/dropbox.js"
import * as messageModal from "@/store/message-modal.js"

const namespace: string = 'modelAccount';

export default class ModelAccountProfileInformationPage extends Vue {
	@State('modelAccount') modelAccount: ModelAccountState | undefined;
	@Action('setProfileInformation', {namespace}) setProfileInformation: any;
	@Action('setModelTags', {namespace}) saveTags: any;
    @Action('getFormValues', {namespace}) getFormValues: any;
    @Getter('getFormValues', {namespace}) formValues: any;
    @Action('getAllTags', {namespace}) getAllTags: any;
    @Action('getAllLangs', {namespace}) getAllLangs: any;
    @Action('updateModelInfo', {namespace}) saveModelInfo: any;
    @Action('saveFigureInfo', {namespace}) saveFigureInfo: any;
    @Getter('allLangs', {namespace}) allLangs: any;
    @Getter('allLangs', {namespace}) getTags: any;
    @Getter('getModelSettingsTags', {namespace}) getModelSettingsTags: any;

	$refs!: {
		refProfilePhoto: HTMLInputElement
	};

    Dropbox(e : any) {
        Dropbox(e);
    }

	//Теги
	addedTag: any = {
		selected: false,
		name: '',
		price: '',
        description: '',
        id: -1
	};

	tags: any = [

	];

	AddCustomTag()
	{
		let newTag = {
            selected: true,
            name: '',
            price: '',
            description: '',
            id: Math.random(),
			custom: true
        };

		this.tags.push(newTag);
	}

    DeleteTag(id : number)
	{
        this.tags.splice(id, 1);
	}

	profileInformation: any = {
		username: '',

		age: {value: "", require: true, class: ""},
		ethnicOrigin: {value: "", require: true, class: ""},
		sexualPreferences: {value: "", require: true, class: ""},
		nativeLanguage: {value: "", require: true, class: ""},
		moreLanguage: {value: "", require: false, class: ""},

        height: {value: "", require: false, class: ""},
        weight: {value: "", require: false, class: ""},
        figure: {value: "", require: false, class: ""},
        hair_color: {value: "", require: false, class: ""},
        eye_color: {value: "", require: false, class: ""},
        breast_size: {value: "", require: false, class: ""},
        booty_size: {value: "", require: false, class: ""},
        intimate_haircut: {value: "", require: false, class: ""},

		country: '',
		city: '',
		growth: '',
		bodyType: '',
		eyeColor: '',
		buttSize: '',
		//weight: '',
		hairColor: '',
		breastSize: '',
		intimateHaircut: '',
		about: '',
		image: '',
		tags: []
	};

	formsValues : any = {
        sexual_preferences: []
	};

	tagInputValue: string = '';
	photoValue: string = '';

	async handleFileUpload() {
		var _this = this;

		const photo = this.$refs.refProfilePhoto.files![0];
        console.log('Avatar toBase64', photo);
		this.photoValue = this.$refs.refProfilePhoto.value;
		return await toBase64(photo).then(function (data) {
            console.log('...', data);
            _this.profileInformation.image = data;
        });
	}

	TagChange(e : any, value : any, paramName : any, filter : any)
	{
		if (filter == "number")
            value[paramName] = value[paramName].replace(/[^\d\.]/g, '');

		if (!value[paramName])
            e.target.classList.add('error');
		else
            e.target.classList.remove('error');
	}

    AgeFilter(e : any)
    {
        this.profileInformation.age.class = '';
        this.profileInformation.age.value = this.profileInformation.age.value.replace(/[^+\d]/g, '').substr(0, 2);
    }

	RefreshValidate()
	{
        $('.js-required').each(function () {
            if (!$(this).val())
                $(this).addClass('error');
            else
                $(this).removeClass('error');
        });
	}

	onSubmit() {
	    var _this = this;

		//Основая информация (/model/store-model-info)
		var data = {
            age: this.profileInformation.age.value,
            ethnic_origin: this.profileInformation.ethnicOrigin.value,
            sexual_preferences: this.profileInformation.sexualPreferences.value,
            native_language_id: this.profileInformation.nativeLanguage.value,
            additional_language_id: this.profileInformation.moreLanguage.value,
            about: this.profileInformation.about,
			avatar: this.profileInformation.image,
		};

        //Фигура (/model/set-body-parameters)
		var dataFigure = {
            height: this.profileInformation.height.value,
            weight: this.profileInformation.weight.value,
            figure: this.profileInformation.figure.value,
            hair_color: this.profileInformation.hair_color.value,
            eye_color: this.profileInformation.eye_color.value,
            breast_size: this.profileInformation.breast_size.value,
            booty_size: this.profileInformation.booty_size.value,
            intimate_haircut: this.profileInformation.intimate_haircut.value,
        };

        //Валидация
        var errors = 0;
        Object.keys(this.profileInformation).forEach(function(key) {
            if (_this.profileInformation[key].require && !_this.profileInformation[key].value.trim())
            {
                _this.profileInformation[key].class = "error";
                errors++;
            }
        });

        if (errors > 0) {
            messageModal.Show("Ошибка", "Заполните обязательные поля формы");
            $('#pillsMainTab').click();
            return;
        }

        //Сохраняем базовые данные
        locker.Show();
		console.log('Save Model Info', data);
        this.saveModelInfo(data);

        //Сохраняем данные о фигуре
        console.log('Save Model Figure Info', dataFigure);
		this.saveFigureInfo(dataFigure);

        //Теги
        let saveTags = [];
        for (let tag of this.tags) {
            if (tag.selected)
            {
                if (tag.custom)
                {
					if (!tag.name) {
                        tag.className = "error";
                        errors++;
					}

                    if (!tag.time) {
                        tag.classTime = "error";
                        errors++;
					}

                    if (!tag.price) {
                        tag.classPrice = "error";
                        errors++;
					}

                    saveTags.push({
                        name: tag.name,
                        price: tag.price,
                        time: tag.time
                    });
                }
                else
                {
                    saveTags.push({
                        id: tag.id,
                        name: tag.name,
                        price: tag.price
                    });
                }
            }
        }

        if (saveTags.length < 3) {
            messageModal.Show("Ошибка", "Выберите хотя бы 3 тега");
            $('#pillsTagsTab').click();
            locker.Hide();
            return;
        }

        if (errors > 0) {
            messageModal.Show("Ошибка", "Заполните все теги");
            locker.Hide();
            return;
        }

        //Сохраняем теги
        console.log('Save Model Tags', dataFigure);
        this.saveTags(saveTags).then(function () {
            locker.Hide();
            console.log('Save complate', data);
            router.push({name: 'model-account-payment-information'}).then(r => r);
        });
	}

    mounted() {
		var _this = this;

        messageModal.Init();

        this.getFormValues();

        this.getAllLangs();

        this.getAllTags().then(function () {
            _this.tags = _this.getModelSettingsTags;
            console.log(_this.tags);
        });
    }
}
